export const formatPrice = (p)=>{
	return new Intl.NumberFormat('id-ID', {style: 'currency', currency: 'IDR'}).format(p);
};

export const formatDateToString = (n) => {

	const date = new Date(n);
	// 01, 02, 03, ... 29, 30, 31
	const dd = (date.getDate() < 10 ? '0' : '') + date.getDate();
	// 01, 02, 03, ... 10, 11, 12
	const mm = ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1);
	// 1970, 1971, ... 2015, 2016, ...
	const yyyy = date.getFullYear();
	return (yyyy + '-' + mm + '-' + dd);
};

export const formatUnits = (n, v)=>{
	if(n==='temperature'){
		return `${v}°C`;

	} else if(n==='relative_humidity'){
		return `${v}%`;

	} else if(n==='conductivity'){
		return `${v}μS/cm`;

	} else if(n==='mineral'){
		return `${v}ppm`;

	}else{
		return v;
	}
};

export const getDayAndDate = (n) =>{
	const options = { weekday: 'long', month: 'long', day: '2-digit' };

	return n.toLocaleDateString('id-ID', options);
};

export const getDateandMonth = (n) =>{
	const options = { month: 'long', day: '2-digit' };

	return n.toLocaleDateString('id-ID', options);
};



export const getTimeZone = (n) =>{
	const timezone = n.getTimezoneOffset();
	if(timezone === -420){
		return 'WIB';
	}else if(timezone === -480){
		return 'WITA';
	} else if(timezone === -540){
		return 'WIT';
	} else {
		return '';
	}
};
export const getDateAndTime = (n) =>{
	const options = {month: 'long', day: 'numeric' };

	const date =  n.toLocaleDateString('id-ID', options);
	const time = n.toLocaleTimeString('id-ID', { hour12: false, hour: '2-digit', minute: '2-digit' });
	const timezone = getTimeZone(n);

	return date+' '+time+' '+timezone;
};