import React from 'react';
import './LoadingBar.css';

function LoadingBar() {
	return (
		<div>
			<div className="bar">
				<input className="bar-input" type="radio" name="input" id="input_0"/>
				<div className="bar-view">
					<label className="bar-button" htmlFor="input_0"></label>
				</div>
				<input className="bar-input" type="radio" name="input" id="input_1"/>
				<div className="bar-view">
					<label className="bar-button" htmlFor="input_1"></label>
				</div>
				<input className="bar-input" type="radio" name="input" id="input_2"/>
				<div className="bar-view">
					<label className="bar-button" htmlFor="input_2"></label>
				</div>
				<input className="bar-input" type="radio" name="input" id="input_3"/>
				<div className="bar-view">
					<label className="bar-button" htmlFor="input_3"></label>
				</div>
				<input className="bar-input" type="radio" name="input" id="input_4"/>
				<div className="bar-view">
					<label className="bar-button" htmlFor="input_4"></label>
				</div>
			</div>
			<div className="command loadingtext">Memuat Data</div>
		</div>
	);
}

export default LoadingBar;