import './WeatherPanel.css';

import {useEffect, useRef, useState} from 'react';
import WeatherTimeContent from './weatherTimeContent/weatherTimeContent';

const WeatherPanel = (props) => {

	const data = props.data;
	const station = props.name;

	const panelRef = useRef(null);
	const wPanelRef = useRef(null);

	function clickRotate() {
		panelRef.current.classList.toggle('rotated');
	}

	useEffect(()=>{
		panelRef.current.addEventListener('click', clickRotate);

	}, [ panelRef]);


	return(
		<div className={'WeatherPanel'} >
			<div className={'PanelContainer'}>
				<div className={'Panel'} ref={panelRef}>
					<div className={'PanelContents PanelToday'} >
						<div className={'StationTitle'}>
							<table className={'StationTable'}>
								<tbody>
									<tr>
										<td colSpan={2}> Prakiraan Cuaca Hari Ini </td>
									</tr>
									<tr>
										<td className={'StationInfo'}>Stasiun Cuaca {station}</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div className={'PanelDepth'}>
							<div className={'PanelInfoContainer'}>
								<WeatherTimeContent data={data} time={'dini'} day={'today'} />
							</div>
							<div className={'PanelInfoContainer'}>
								<WeatherTimeContent data={data} time={'pagi'} day={'today'} />
							</div>
							<div className={'PanelInfoContainer'}>
								<WeatherTimeContent data={data} time={'siang'} day={'today'} />
							</div>
							<div className={'PanelInfoContainer'}>
								<WeatherTimeContent data={data} time={'malam'} day={'today'} />
							</div>

						</div>
					</div>
					<div className={'PanelContents PanelTomorrow'}>
						<div className={'StationTitle'}>
							<table className={'StationTable'}>
								<tbody>
									<tr>
										<td colSpan={2}> Prakiraan Cuaca Besok </td>
									</tr>
									<tr>
										<td className={'StationInfo'}>Stasiun Cuaca {station}</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div className={'PanelDepth'}>
							<div className={'PanelInfoContainer'}>
								<WeatherTimeContent data={data} time={'dini'} day={'tommorrow'} />
							</div>
							<div className={'PanelInfoContainer'}>
								<WeatherTimeContent data={data} time={'pagi'} day={'tommorrow'} />
							</div>
							<div className={'PanelInfoContainer'}>
								<WeatherTimeContent data={data} time={'siang'} day={'tommorrow'} />
							</div>
							<div className={'PanelInfoContainer'}>
								<WeatherTimeContent data={data} time={'malam'} day={'tommorrow'} />
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	);
};

export default WeatherPanel;