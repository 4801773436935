import SvgHighTemp from '../static/icons/measurementIcons/HighTemp';
import SvgLowTemp from '../static/icons/measurementIcons/LowTemp';
import SvgHumid from '../static/icons/measurementIcons/Humid';
import SvgDry from '../static/icons/measurementIcons/Dry';

import * as GiIcons from 'react-icons/gi';
import * as MDIcons from 'react-icons/md';


export  const cyclePredKeyGen = (n) => {
	if(n === 'falling'){
		return 'rising_in';
	}else{
		return'falling_in';
	}
};

export const generateAPIURL = (a, l, r, d) => {
	const apiURL = process.env.REACT_APP_API_URL;
	if (a === 'price'){
		if(l === 'national'){
			return `${apiURL}/nprices/${l}/date=${d}`;
		}
		else{
			return `${apiURL}/nprices/${l}/${r}/date=${d}`;
		}
	} else if (a ==='mst'){
		if(l === 'national'){
			return `${apiURL}/mst/${l}/date=${d}`;
		}
		else{
			return `${apiURL}/mst/${l}/${r}/date=${d}`;
		}
	}
};

export const  getInfoSVG = (m, i)=>{
	if (m==='temperature' && i===true){
		return <SvgHighTemp width={'4.5rem'} height={'6.5rem'} />;
	}
	else if (m==='temperature' && i===false){
		return <SvgLowTemp width={'4.5rem'} height={'6.5rem'} />;
	}
	else if((m==='relative_humidity' && i===true))
	{
		return <SvgHumid width={'4.5rem'} height={'6.5rem'} />;
	}
	else if((m==='relative_humidity' && i===false))
	{
		return <SvgDry width={'4.5rem'} height={'6.5rem'}/>;
	}

};

export const  getInterpTitle = (m, i)=>{
	if (m==='temperature' && i===true){
		return 'Suhu Tinggi';
	}
	else if (m==='temperature' && i===false){
		return 'Suhu Rendah';
	}
	else if((m==='relative_humidity' && i===true))
	{
		return 'Kelembapan Tinggi';
	}
	else if((m==='relative_humidity' && i===false))
	{
		return 'Kelembapan Rendah';
	}

};

export    const getIcons = (m, i)=>{
	if (m==='temperature' && i==='max'){
		return <GiIcons.GiThermometerHot />;
	}
	else if (m==='temperature' && i==='min'){

		return <GiIcons.GiThermometerCold />;
	}
	else if((m==='relative_humidity' && i==='max'))
	{
		return <MDIcons.MdWaterDrop />;
	}
	else if((m==='relative_humidity' && i==='min'))
	{
		return <MDIcons.MdOutlineWaterDrop />;
	}

};

export const interpHandler = (d, m) =>{
	const interp = d[`interp_${m}`];
	if(interp === 'high'){
		return true;
	} else {
		return false;
	}

};

export const getInterpValue = (d, n, i )=>{
	if(n==='temperature' && i===true){
		return `${d[`high_${n}`]}°C`;
	} else if(n==='temperature' && i===false){
		return `${d[`low_${n}`]}°C`;
	} else if(n==='relative_humidity' && i===true){
		return `${d[`high_${n}`]}%`;
	} else if(n==='relative_humidity' && i===false){
		return `${d[`low_${n}`]}%`;
	}
};

export const getTrendStatus = (m,s) =>{
	if(m === 'temperature'){
		if(s === 'falling'){
			return ['Turun', 'Mulai bergerak naik pada'];
		}else if(s === 'rising'){
			return ['Naik', 'Mulai bergerak turun pada'];
		}
	} else if( m === 'relative_humidity'){
		if(s === 'falling'){
			return ['Turun', 'Mulai bergerak naik pada'];
		}else if(s === 'rising'){
			return ['Naik', 'Mulai bergerak turun pada'];
		}
	} else {
		return '';
	}

};

export const standardizedEC = (conData, tempData) => {
	const conArr = conData.map(function (d) {
		return d.y;
	});
	const tempArr = tempData.map(function (d) {
		return d.y;
	});
	const sEC = [];

	for (let i = 0; i < conArr.length; i++) {
		const pow = tempArr[i]/26.815;

		const sec = conArr[i] * (0.4470 + (1.403 * Math.pow(Math.E, pow)));
		sEC.push(sec);
	}
	return sEC;

};

export const convertECtoPS = (conData) => {
	const conArr = conData.map((d) => {
		return d.y;
	});

	const PSArr = [];

	for(let i =0; i< conArr.length; i++){
		const practicalSalinity = (0.7317 * conArr[i]) - 3.7635;
		PSArr.push(practicalSalinity);
	}

	return PSArr;
};