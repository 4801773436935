import logo from '../../logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route} from 'react-router-dom';

import Overview from '../overview/overview';
import Sumut from "../sumut/sumut";
function App() {
	return (
		// <div>
		// 	<Overview />
		// </div>
		<BrowserRouter>
			<Routes>
				{/*<Route exact path="/"  element={<Overview />} />*/}
				<Route path="/overview/:type"  element={<Overview />} />
				<Route exact path="/"  element={<Sumut />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
