import React, {useState, useEffect} from 'react';
import {MapContainer, Marker, Popup, SVGOverlay, TileLayer} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './sensorMap.css';
import semnetMarker from './markers/SemnetMarker.png';
import stationMarker from './markers/StationMarker.png';
import L from 'leaflet';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import {dummyStationData} from './dummyStationData';
import WeatherPanel from '../widgets/weatherPanel/WeatherPanel';
import {StationCycle} from '../widgets/cyclePanel/stationCycle';
import WeatherStats from "../widgets/WeatherStatsPanel/WeatherStats";

var SemnetMarker = L.icon({
	iconUrl: semnetMarker,
	shadowUrl: iconShadow,
	iconSize: new L.Point(50, 50),
});



L.Marker.prototype.options.icon = SemnetMarker;

var StationMarker = new L.icon({
	iconUrl: stationMarker,
	shadowUrl: iconShadow,
	iconSize: new L.Point(50, 50),
});



export const SensorMap = (props) => {
	const stationData = props.stationData;
	const powerData = props.powerData;
	const weatherObject = props.wobj;

	const latitude = '2.7605';
	const longitude = '98.45527';
	const sensorLoc = props.location;
	// const latitude = '2.38142896';
	// const longitude = '98.704115';
	// const sensorLoc = [2.38142896, 98.704115];

	const stationOne = Object.keys(stationData)[0];
	const stationTwo = Object.keys(stationData)[1];
	const stationThree = Object.keys(stationData)[2];

	const stationOneLoc = [parseFloat(stationData[stationOne]['latitude']), parseFloat(stationData[stationOne]['longitude'])];
	const stationTwoLoc = [parseFloat(stationData[stationTwo]['latitude']), parseFloat(stationData[stationTwo]['longitude'])];
	const stationThreeLoc = [parseFloat(stationData[stationThree]['latitude']), parseFloat(stationData[stationThree]['longitude'])];

	const [selectedStationData, setSelectedStationData] = useState(stationData[stationOne]);
	const [isAssigned, setIsAssigned] = useState(false);
	const [showStations, setShowStations] = useState(false);
	const [selectedStationName, setSelectedStationName] = useState(stationOne);



	return(
		<div className={'SensorWeatherMapContainer'}>

			<MapContainer className={'mapContainer'} center={sensorLoc} zoom={9} scrollWheelZoom={true}>
				<TileLayer
					attribution='Map Data &copy; OpenStreetMap contributors.'
					url="http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				/>
				<Marker
					position={sensorLoc}
					eventHandlers={{
						click: () => {
							setShowStations((prevState) => !prevState);
						}
					}}
				>
					<Popup>
						<b>Sumbul, Dairi</b>
						<br/>
                            Ketuk untuk melihat lokasi Stasiun Cuaca terdekat
					</Popup>
				</Marker>

				{showStations && (
					<Marker
						icon={StationMarker}
						position={stationOneLoc}
						eventHandlers={{
							click: () => {
								setSelectedStationName(stationOne);
								setSelectedStationData(stationData[stationOne]);
								setIsAssigned(true);
							}
						}}
					>
						<Popup>
                                Stasiun Cuaca {stationOne}
						</Popup>
					</Marker>

				)}
				{showStations && (
					<Marker
						icon={StationMarker}
						position={stationTwoLoc}
						eventHandlers={{
							click: () => {
								setSelectedStationName(stationTwo);
								setSelectedStationData(stationData[stationTwo]);
								setIsAssigned(true);
							}
						}}
					>
						<Popup>
                                Stasiun Cuaca {stationTwo}
						</Popup>
					</Marker>

				)}
				{showStations && (
					<Marker
						icon={StationMarker}
						position={stationThreeLoc}
						eventHandlers={{
							click: () => {
								setSelectedStationName(stationThree);
								setSelectedStationData(stationData[stationThree]);
								setIsAssigned(true);
							}
						}}
					>
						<Popup>
                                Stasiun Cuaca {stationThree}
						</Popup>
					</Marker>

				)}

			</MapContainer>

			<div className={'InfoWrapper'}>
				<div className={'ChildPanel'}>
					<WeatherPanel data={selectedStationData} name={selectedStationName} ></WeatherPanel>
				</div>
				<div className={'ChildPanel'}>
					<StationCycle data={powerData} />
				</div>
			</div>

			<div className={'WSPanelContainer'}>
				<WeatherStats data={weatherObject}/>
			</div>
		</div>
	);
};