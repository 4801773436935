import * as React from 'react';

const SvgLogo = (props) => (
	<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' {...props}>
		<path
			d='M336.92 362.99c20.87 5.74 33.65 22.34 36.23 46.89.41 3.92 1.31 7.79 1.67 11.71.17 1.83.32 5.24-2.98 3.3-12-7.04-25.36-12.08-33.84-24.3-7.77-11.2-9.28-23.55-8.53-36.55.16-2.71 1.15-2.79 7.44-1.06ZM309.34 277.37c-3.84 1.87-3.83 3.09-2.63 4.83 11.6 16.82 35.69 24.58 54.88 17.82 5.63-1.99 20.06-6.94 20.06-6.94s-24.94-21.86-41.83-23.08c-11.85-.86-22.27 2.76-30.47 7.38ZM413.79 319.15c-20.27-15.78-40.33-16.05-61.04-3.9-3.37 1.98-2.93 4.1-.5 6.51 17.71 17.59 36.88 21.4 60.07 12.02 3.31-1.34 12.87-5.5 12.87-5.5s-8.89-7.52-11.39-9.13ZM437.26 351.32c-19.41-9.12-36.1-6.51-52.03 4.02-2.23 1.48-2.15 3.02-.32 5.01 15.76 17.08 34.86 21.58 56.85 13.34 4.71-1.77 16.89-7.21 16.89-7.21s-14.28-12.07-21.39-15.15Z'
			style={{
				fill: '#00aa3b',
			}}
		/>
		<path
			d='M318.97 356.18c-4.86 20.64-6.19 36.66-6.45 55.8-.24 17.7 10.83 58.72 11.14 59.98-.97-.69-13.18-22.21-15.58-34.05-3.66-18.05-5.74-36.05-2.85-54.33 3.1-19.67 8.39-38.58 15.12-57.2.93-2.58.34-4.36-1.78-6.05-14.51-11.51-28.64-23-41.51-36.45-5.37-5.62-16.11-19.83-15.07-20.75.12-.1 29.26 24.2 43.76 34.54 16.16 11.81 30.32 23.86 44.91 38.18a78.54 78.54 0 0 1 9.88 11.8c9.24 13.59 16.57 25.95 23.32 40.73.23.49 4.46 9.85 2.39 13.62-2.91-2.21-14.87-25-24.62-40.8-12.57-18.15-19.3-23.45-23.83-27.55-9.82-8.9-10.79-3.1-11.93.55-2.56 8.2-4.92 15.77-6.88 21.98Z'
			style={{
				fill: '#00aa3b',
			}}
		/>
		<path
			d='M303.8 328.27c4.26.36 4.69 1.5 4.2 3.55-4.76 19.87-24.43 35.8-44.76 36.4-5.97.18-21.22.76-21.22.76s15.38-29.38 30.7-36.61c10.74-5.07 21.76-5.45 31.08-4.1ZM329.47 424.26c20.87 5.74 33.65 22.34 36.23 46.89.41 3.92 1.31 7.79 1.67 11.71.17 1.83.32 5.24-2.98 3.3-12-7.04-25.36-12.08-33.84-24.3-7.77-11.2-9.28-23.55-8.53-36.55.16-2.71 1.15-2.79 7.44-1.06ZM261.15 472.8c4.55-20.96 16.77-32.63 34.81-38.88 2.53-.88 3.71.12 4.17 2.79 3.99 22.89-4.05 40.8-23.95 53.26-4.27 2.67-15.99 9.03-15.99 9.03s-.91-18.68.97-26.19ZM252.61 419.02c3.68-25.42 17.96-39.52 41.3-45.11 3.8-.91 4.95.93 4.87 4.35-.57 24.95-11.76 40.98-34.97 50.3-3.32 1.33-13.09 4.95-13.09 4.95s1.2-11.59 1.88-14.48ZM153.75 335.05c-5.74 20.87-22.34 33.65-46.89 36.23-3.92.41-7.79 1.31-11.71 1.67-1.83.17-5.24.32-3.3-2.98 7.04-12 12.08-25.36 24.3-33.84 11.2-7.77 23.55-9.28 36.55-8.53 2.71.16 2.79 1.15 1.06 7.44ZM239.37 307.46c-1.87-3.84-3.09-3.83-4.83-2.63-16.82 11.6-24.58 35.69-17.82 54.88 1.99 5.63 6.94 20.06 6.94 20.06s21.86-24.94 23.08-41.83c.86-11.85-2.76-22.27-7.38-30.47ZM197.6 411.91c15.78-20.27 16.05-40.33 3.9-61.04-1.98-3.37-4.1-2.93-6.51-.5-17.59 17.71-21.4 36.88-12.02 60.07 1.34 3.31 5.5 12.87 5.5 12.87s7.52-8.89 9.13-11.39ZM165.43 435.39c9.12-19.41 6.51-36.1-4.02-52.03-1.48-2.23-3.02-2.15-5.01-.32-17.08 15.76-21.58 34.86-13.34 56.85 1.77 4.71 7.21 16.89 7.21 16.89s12.07-14.28 15.15-21.39Z'
			style={{
				fill: '#00aa3b',
			}}
		/>
		<path
			d='M160.57 317.1c-20.64-4.86-36.66-6.19-55.8-6.45-17.7-.24-58.72 10.83-59.98 11.14.69-.97 22.21-13.18 34.05-15.58 18.05-3.66 36.05-5.74 54.33-2.85 19.67 3.1 38.58 8.39 57.2 15.12 2.58.93 4.36.34 6.05-1.78 11.51-14.51 23-28.64 36.45-41.51 5.62-5.37 19.83-16.11 20.75-15.07.1.12-24.2 29.26-34.54 43.76-11.81 16.16-23.86 30.32-38.18 44.91a78.54 78.54 0 0 1-11.8 9.88c-13.59 9.24-25.95 16.57-40.73 23.32-.49.23-9.85 4.46-13.62 2.39 2.21-2.91 25-14.87 40.8-24.62 18.15-12.57 23.62-19.15 27.55-23.83 7.28-8.65 8.12-8.45-2.14-13.27-8.18-3.84-14.09-4.51-20.39-5.55Z'
			style={{
				fill: '#00aa3b',
			}}
		/>
		<path
			d='M188.47 301.92c-.36 4.26-1.5 4.69-3.55 4.2-19.87-4.76-35.8-24.43-36.4-44.76-.18-5.97-.76-21.22-.76-21.22s29.38 15.38 36.61 30.7c5.07 10.74 5.45 21.76 4.1 31.08ZM92.48 327.59c-5.74 20.87-22.34 33.65-46.89 36.23-3.92.41-7.79 1.31-11.71 1.67-1.83.17-5.24.32-3.3-2.98 7.04-12 12.08-25.36 24.3-33.84 11.2-7.77 23.55-9.28 36.55-8.53 2.71.16 2.79 1.15 1.06 7.44ZM43.94 259.27c20.96 4.55 32.63 16.77 38.88 34.81.88 2.53-.12 3.71-2.79 4.17-22.89 3.99-40.8-4.05-53.26-23.95-2.67-4.27-9.03-15.99-9.03-15.99s18.68-.91 26.19.97ZM97.72 250.73c25.42 3.68 39.52 17.96 45.11 41.3.91 3.8-.93 4.95-4.35 4.87-24.95-.57-40.98-11.76-50.3-34.97-1.33-3.32-4.95-13.09-4.95-13.09s11.59 1.2 14.48 1.88ZM171.77 149.31c-20.87-5.74-33.65-22.34-36.23-46.89-.41-3.92-1.31-7.79-1.67-11.71-.17-1.83-.32-5.24 2.98-3.3 12 7.04 25.36 12.08 33.84 24.3 7.77 11.2 9.28 23.55 8.53 36.55-.16 2.71-1.15 2.79-7.44 1.06ZM199.36 234.93c3.84-1.87 3.83-3.09 2.63-4.83-11.6-16.82-35.69-24.58-54.88-17.82-5.63 1.99-20.06 6.94-20.06 6.94s24.94 21.86 41.83 23.08c11.85.86 22.27-2.76 30.47-7.38ZM94.91 193.15c20.27 15.78 40.33 16.05 61.04 3.9 3.37-1.98 2.93-4.1.5-6.51-17.71-17.59-36.88-21.4-60.07-12.02-3.31 1.34-12.87 5.5-12.87 5.5s8.89 7.52 11.39 9.13ZM71.44 160.99c19.41 9.12 36.1 6.51 52.03-4.02 2.23-1.48 2.15-3.02.32-5.01-15.76-17.08-34.86-21.58-56.85-13.34-4.71 1.77-16.89 7.21-16.89 7.21s14.28 12.07 21.39 15.15Z'
			style={{
				fill: '#00aa3b',
			}}
		/>
		<path
			d='M189.72 156.12c4.86-20.64 6.19-36.66 6.45-55.8.24-17.7-10.83-58.72-11.14-59.98.97.69 13.18 22.21 15.58 34.05 3.66 18.05 5.74 36.05 2.85 54.33-3.1 19.67-8.39 38.58-15.12 57.2-.93 2.58-.34 4.36 1.78 6.05 14.51 11.51 28.64 23 41.51 36.45 5.37 5.62 16.11 19.83 15.07 20.75-.12.1-29.26-24.2-43.76-34.54-16.16-11.81-30.32-23.86-44.91-38.18a78.54 78.54 0 0 1-9.88-11.8c-9.24-13.59-16.57-25.95-23.32-40.73-.23-.49-4.46-9.85-2.39-13.62 2.91 2.21 14.87 25 24.62 40.8 12.57 18.15 19.43 23.31 23.83 27.55 10.74 10.34 10.5 3.1 11.73-.58 2.72-8.12 5.13-15.74 7.09-21.95Z'
			style={{
				fill: '#00aa3b',
			}}
		/>
		<path
			d='M204.9 184.03c-4.26-.36-4.69-1.5-4.2-3.55 4.76-19.87 24.43-35.8 44.76-36.4 5.97-.18 21.22-.76 21.22-.76s-15.38 29.38-30.7 36.61c-10.74 5.07-21.76 5.45-31.08 4.1ZM179.23 88.04C158.36 82.3 145.58 65.7 143 41.15c-.41-3.92-1.31-7.79-1.67-11.71-.17-1.83-.32-5.24 2.98-3.3 12 7.04 25.36 12.08 33.84 24.3 7.77 11.2 9.28 23.55 8.53 36.55-.16 2.71-1.15 2.79-7.44 1.06ZM247.55 39.5c-4.55 20.96-16.77 32.63-34.81 38.88-2.53.88-3.71-.12-4.17-2.79-3.99-22.89 4.05-40.8 23.95-53.26 4.27-2.67 15.99-9.03 15.99-9.03s.91 18.68-.97 26.19ZM256.09 93.28c-3.68 25.42-17.96 39.52-41.3 45.11-3.8.91-4.95-.93-4.87-4.35.57-24.95 11.76-40.98 34.97-50.3 3.32-1.33 13.09-4.95 13.09-4.95s-1.2 11.59-1.88 14.48ZM357.65 179.42c5.74-20.87 22.34-33.65 46.89-36.23 3.92-.41 7.79-1.31 11.71-1.67 1.83-.17 5.24-.32 3.3 2.98-7.04 12-12.08 25.36-24.3 33.84-11.2 7.77-23.55 9.28-36.55 8.53-2.71-.16-2.79-1.15-1.06-7.44ZM272.04 207c1.87 3.84 3.09 3.83 4.83 2.63 16.82-11.6 24.58-35.69 17.82-54.88-1.99-5.63-6.94-20.06-6.94-20.06s-21.86 24.94-23.08 41.83c-.86 11.85 2.76 22.27 7.38 30.47ZM313.81 102.55c-15.78 20.27-16.05 40.33-3.9 61.04 1.98 3.37 4.1 2.93 6.51.5 17.59-17.71 21.4-36.88 12.02-60.07-1.34-3.31-5.5-12.87-5.5-12.87s-7.52 8.89-9.13 11.39ZM345.98 79.08c-9.12 19.41-6.51 36.1 4.02 52.03 1.48 2.23 3.02 2.15 5.01.32 17.08-15.76 21.58-34.86 13.34-56.85-1.77-4.71-7.21-16.89-7.21-16.89s-12.07 14.28-15.15 21.39Z'
			style={{
				fill: '#00aa3b',
			}}
		/>
		<path
			d='M350.84 197.37c20.64 4.86 36.66 6.19 55.8 6.45 17.7.24 58.72-10.83 59.98-11.14-.69.97-22.21 13.18-34.05 15.58-18.05 3.66-36.05 5.74-54.33 2.85-19.67-3.1-38.58-8.39-57.2-15.12-2.58-.93-4.36-.34-6.05 1.78-11.51 14.51-23 28.64-36.45 41.51-5.62 5.37-19.83 16.11-20.75 15.07-.1-.12 24.2-29.26 34.54-43.76 11.81-16.16 23.86-30.32 38.18-44.91a78.54 78.54 0 0 1 11.8-9.88c13.59-9.24 25.95-16.57 40.73-23.32.49-.23 9.85-4.46 13.62-2.39-2.21 2.91-25 14.87-40.8 24.62-18.15 12.57-23.28 19.46-27.55 23.83-10.54 10.77-3.1 10.79.55 11.93 8.2 2.56 15.77 4.92 21.98 6.88Z'
			style={{
				fill: '#00aa3b',
			}}
		/>
		<path
			d='M322.93 212.54c.36-4.26 1.5-4.69 3.55-4.2 19.87 4.76 35.8 24.43 36.4 44.76.18 5.97.76 21.22.76 21.22s-29.38-15.38-36.61-30.7c-5.07-10.74-5.45-21.76-4.1-31.08ZM418.92 186.87c5.74-20.87 22.34-33.65 46.89-36.23 3.92-.41 7.79-1.31 11.71-1.67 1.83-.17 5.24-.32 3.3 2.98-7.04 12-12.08 25.36-24.3 33.84-11.2 7.77-23.55 9.28-36.55 8.53-2.71-.16-2.79-1.15-1.06-7.44ZM467.46 255.2c-20.96-4.55-32.63-16.77-38.88-34.81-.88-2.53.12-3.71 2.79-4.17 22.89-3.99 40.8 4.05 53.26 23.95 2.67 4.27 9.03 15.99 9.03 15.99s-18.68.91-26.19-.97ZM413.69 263.73c-25.42-3.68-39.52-17.96-45.11-41.3-.91-3.8.93-4.95 4.35-4.87 24.95.57 40.98 11.76 50.3 34.97 1.33 3.32 4.95 13.09 4.95 13.09s-11.59-1.2-14.48-1.88Z'
			style={{
				fill: '#00aa3b',
			}}
		/>
	</svg>
);

export default SvgLogo;
