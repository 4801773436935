import React, { useState }  from 'react';
import * as FaIcons from 'react-icons/fa';
import './Dropdown.css';

export const Dropdown = ({ dropdownOptions, selected, onSelectedChange}) => {

	const [open, setOpen] = useState(false);

	const renderedOptions = dropdownOptions.map(option => {

		if (option.value === selected.value) {
			return null;
		}
		return (
			<div
				key={option.value}
				className="item"
				onClick={() => onSelectedChange(option)}
			>
				{option.label}
			</div>

		);
	});



	return (

		<div className="DropdownWrapper">
			<div className="selectedArea" onClick={() => setOpen(!open)}>
				<table className={'TableSelected'}>
					<tbody>
						<tr>
							<td className={'SelectedLabel'} colSpan={2}> {selected.label===undefined ? `${selected}` : `${selected.label}`}</td>
							<td className={'SelectedIcons'}> <FaIcons.FaChevronDown /></td>
						</tr>
					</tbody>
				</table>
			</div>
			<div className={`drop${open ? 'Visible' : 'Hidden'}`}>
				<div onClick={() => setOpen(!open)}
					className={`menu${open ? 'Visible' : 'Hidden'}`}>{renderedOptions}</div>
			</div>

		</div>
	);
};

