import React, { useEffect, useState, useRef } from 'react';
import { Network } from 'vis-network/standalone';
import './mst.css';

import {retrieveBorderColor} from '../../utils/retrieveBorderColor';
import {retrieveTitle} from '../../utils/retrieveTitle';

import BawangMerah from './icons/bawang_merah.png';
import BawangPutih from './icons/bawang_putih.png';
import Beras from './icons/beras.png';
import DagingAyam from './icons/daging_ayam.png';
import DagingSapi from './icons/daging_sapi.png';
import CabaiRawit from './icons/cabai_rawit.png';
import CabaiMerah from './icons/cabai_merah.png';
import TelurAyam from './icons/telur_ayam.png';
import GulaPasir from './icons/gula_pasir.png';
import MinyakGoreng from './icons/minyak_goreng.png';


export const MSTView = (props)=> {

	const domNode = useRef(null);
	const network = useRef(null);

	const edges = props.graphData;




	useEffect(() => {

		const nodes = [
			{
				id: 'bawang_merah',
				label: 'Bawang Merah',
				title: retrieveTitle(props.nPrice, props.level,'bawang_merah'),
				shape: 'circularImage',
				image: BawangMerah,
				color:{
					border: retrieveBorderColor(props.nPrice, props.dPrice, 'bawang_merah')
				}
			},
			{
				id: 'bawang_putih',
				label: 'Bawang Putih',
				title: retrieveTitle(props.nPrice, props.level,'bawang_putih'),
				shape: 'circularImage',
				image: BawangPutih,
				color:{
					border: retrieveBorderColor(props.nPrice, props.dPrice, 'bawang_putih')
				}
			},
			{
				id: 'beras',
				label: 'Beras',
				title: retrieveTitle(props.nPrice, props.level,'beras'),
				shape: 'circularImage',
				image: Beras,
				color:{
					border: retrieveBorderColor(props.nPrice, props.dPrice, 'beras')
				}
			},
			{
				id: 'daging_ayam',
				label: 'Daging Ayam',
				title: retrieveTitle(props.nPrice, props.level,'daging_ayam'),
				shape: 'circularImage',
				image: DagingAyam,
				color:{
					border: retrieveBorderColor(props.nPrice, props.dPrice,'daging_ayam')
				}
			},
			{
				id: 'daging_sapi',
				label: 'Daging Sapi',
				title: retrieveTitle(props.nPrice, props.level, 'daging_sapi'),
				shape: 'circularImage',
				image: DagingSapi,
				color:{
					border: retrieveBorderColor(props.nPrice, props.dPrice, 'daging_sapi')
				}
			},
			{
				id: 'cabai_rawit',
				label: 'Cabai Rawit',
				title: retrieveTitle(props.nPrice, props.level,'cabai_rawit'),
				shape: 'circularImage',
				image: CabaiRawit,
				color:{
					border: retrieveBorderColor(props.nPrice, props.dPrice, 'cabai_rawit')
				}
			},
			{
				id: 'cabai_merah',
				label: 'Cabai Merah',
				shape: 'circularImage',
				title: retrieveTitle(props.nPrice, props.level,'cabai_merah'),
				image: CabaiMerah,
				color:{
					border: retrieveBorderColor(props.nPrice, props.dPrice, 'cabai_merah')
				}
			},
			{
				id: 'telur_ayam',
				label: 'Telur Ayam',
				title: retrieveTitle(props.nPrice, props.level,'telur_ayam'),
				shape: 'circularImage',
				image: TelurAyam,
				color:{
					border: retrieveBorderColor(props.nPrice, props.dPrice,'telur_ayam')
				}
			},
			{
				id: 'gula_pasir',
				label: 'Gula Pasir',
				title: retrieveTitle(props.nPrice, props.level,'gula_pasir'),
				shape: 'circularImage',
				image: GulaPasir,
				color:{
					border: retrieveBorderColor(props.nPrice, props.dPrice, 'gula_pasir')
				}
			},
			{
				id: 'minyak_goreng',
				label: 'Minyak Goreng',
				title: retrieveTitle(props.nPrice, props.level,'minyak_goreng'),
				shape: 'circularImage',
				image: MinyakGoreng,
				color:{
					border: retrieveBorderColor(props.nPrice, props.dPrice, 'minyak_goreng')
				}
			},
		];

		const data = {
			nodes,
			edges
		};

		network.current = new Network(domNode.current, data, options);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [edges]);



	var options = {
		autoResize: true,
		nodes: {
			chosen:false,
			borderWidth: 3,
			borderWidthSelected:3,
			size: 35,

			color: {
				background: 'rgba(0,0,0, 0.8)',
			},
			font: {
				size: 16,
				color: '#ffffff',

			},
		},
		edges: {
			color: '#C2C2C2',
			length: 200,
		},

		interaction: {
			tooltipDelay: 20,
		},

		physics: {
			enabled: true,
			barnesHut: {
				centralGravity: 0.9,
				avoidOverlap: 2
			},
		}
	};

	return (
		<>
			<div className={'MSTCanvas'} ref={domNode}/>
		</>

	);

};
export default MSTView;