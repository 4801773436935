import React, {useEffect, useImperativeHandle, useRef, useState} from 'react';
import {comColor, comColorAlpha, cycleOptions} from '../../../utils/dictionary';
import {Dropdown} from '../../../components/Dropdown/Dropdown';
import {cyclePredKeyGen} from '../../../utils/generator';
import './stationCycle.css';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-moment';
import {
	Chart,
	TimeScale, //Import timescale instead of category for X axis
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
} from 'chart.js';
import Annotation  from 'chartjs-plugin-annotation';

Chart.register(
	TimeScale, //Register timescale instead of category for X axis
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	Annotation,
);

export const StationCycle = (props) => {
	const data = props.data;
	const [selectedMeasurement, setSelectedMeasurement] = useState({label:'Suhu Udara',value:'temperature'});
	const [measurement, setMeasurement] = useState('');
	const [currentVal, setCurrentVal] = useState();

	const [selectedCycle, setSelectedCycle] = useState({});


	const [cycleStatus, setCycleStatus] = useState('');
	const [nextCycle, setNextCycle] = useState('');
	const [cycleTS, setCycleTS] = useState([]);

	const [chartData, setChartData] = useState({});
	const [annotationValue, setAnnotationValue] = useState(data['temperature']['cycle']['mean']);
	const [isChartDataSet, setIsChartDataSet] = useState(false);

	const [isAssigned, setIsAssigned] = useState(false);

	const [selectedAnnualMean, setSelectedAnnualMean] = useState({});





	useEffect(() => {

		const m = selectedMeasurement.value;

		setMeasurement(m);
		setCurrentVal(data[m]['cycle']['now']);
		setSelectedCycle(data[m]['cycle']);
		setCycleStatus(data[m]['cycle']['status']);
		setNextCycle(data[m]['cycle'][cyclePredKeyGen(data[m]['cycle']['status'])]);
		setCycleTS(data[m]['cycle']['ts']);
		setAnnotationValue(data[m]['cycle']['mean']);
		setSelectedAnnualMean(data[m]['annual_mean']);

		const tsData = data[selectedMeasurement.value]['cycle']['ts'];


		let currentdate = new Date().toISOString().slice(0, 10);
		const now = [{'x': currentdate, 'y': data[m]['cycle']['now']}];
		const cd = {
			labels: tsData.map(function (d) {
				return d.x;
			}),
			datasets: [
				{
					label: 'Now',
					data: now,
					fill: true,
					tension: 0.1,
					showTooltips: false ,
					elements: {
						point:{
							radius: 5,
							backgroundColor: 'white'
						}
					}
				},
				{
					label: selectedMeasurement['label'],
					data: tsData.map(function (d) {
						return d.y;
					}),
					fill: false,
					backgroundColor: '#089AF3',
					borderColor: '#FBCF23',
					borderWidth: 4,
					tension: 0.4,
					showTooltips: false,
					elements: {
						point:{
							radius: 0
						}
					}
				},
			]
		};
		setChartData(cd);
		setIsAssigned(true);

	},[selectedMeasurement] );





	return(

		<div className={'CycleWrapper'}>
			<div className={'StationTitle'}>
				<table className={'StationTable'}>
					<tbody>
						<tr>
							<td colSpan={2}> Siklus Komponen Cuaca </td>
						</tr>
					</tbody>
				</table>
			</div>

			{/*{currentVal}*/}
			{isAssigned && (
				<div className={'TestText'}>
					<Line
						className={'LineChart'}
						data={chartData}
						options={{
							responsive:false,
							maintainAspectRatio: false,
							scales: {
								x: {
									type: 'time',
									time: {
										displayFormats: {
											month: 'MMM'
										},
										unit: 'month'
									},
									ticks: {
										color:'#FFFFFF',
										maxRotation: 0,
										minRotation: 0,
									},
									grid:{
										display : false,
										borderWidth:0,
									},

								},
								y: {
									display:true,
									ticks: {
										color:'#FFFFFF'
									}

								}
							},

							plugins: {
								legend:{
									display:false
								},
								title:{
									display:false,
								},
								tooltip:{
									display:false,
								},
								annotation: {
									annotations: {
										meanLine: {
											type: 'line',
											scaleID: 'y',
											value: `${annotationValue}`,
											borderColor: 'rgba(255,255,255,0.6)',
											borderWidth: 2,
											label: {
												content: 'Mean',
												enabled: true
											},
											click(context) {
												// The annotation is is bound to the `this` variable
												console.log('Annotation', context);
											}
										}
									},
								}
							},
						}}
					/>
				</div>
			)}
			<div className={'DropdownContainer'}>
				<Dropdown
					dropdownOptions={cycleOptions}
					selected={selectedMeasurement}
					onSelectedChange={setSelectedMeasurement}
				/>


			</div>



		</div>


	);
};