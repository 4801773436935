import './sumut.css';

import React, {useState} from 'react';
import L from "leaflet";
import semnetMarker from "../../views/sensorMap/markers/SemnetMarker.png";
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet";
import {sumutListSensor} from "../../utils/dictionary";
import {Button} from "../../components/Button/Button";
import {Link} from "react-router-dom";


var SemnetMarker = L.icon({
	iconUrl: semnetMarker,
	shadowUrl: iconShadow,
	iconSize: new L.Point(50, 50),
});
L.Marker.prototype.options.icon = SemnetMarker;


function Sumut(props) {

	const [selectedLoc, setSelectedLoc] = useState(sumutListSensor[0]);

	return (
		<div className={'indexPage'}>

			<div className={'centerContainer'}>

				<div>
					<div className={'centerTitle'}>
						Provinsi Sumatera Utara
					</div>
				</div>
				<MapContainer className={'sumutMapContainer'} center={['2.7605', '98.45527']} zoom={9} scrollWheelZoom={true}>
					<TileLayer
						attribution='Map tiles by Carto, under CC BY 3.0. Data by OpenStreetMap, under ODbL.'
						url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png"
					/>

					<Marker
						position={[sumutListSensor[0]['latitude'], sumutListSensor[0]['longitude']]}
						eventHandlers={{
							click: () => {
								setSelectedLoc(sumutListSensor[0]);
								console.log('selected - ' , selectedLoc)
							}
						}}
					>
						<Popup>
							<b>{sumutListSensor[0]['locationName']}</b>
							<br/>
							Klik untuk menetapkan pilihan lokasi sensor menjadi {sumutListSensor[0]['locationName']}
						</Popup>
					</Marker>

					<Marker
						position={[sumutListSensor[1]['latitude'], sumutListSensor[1]['longitude']]}
						eventHandlers={{
							click: () => {
								setSelectedLoc(sumutListSensor[1]);
								console.log('selected - ' , selectedLoc)
							}
						}}
					>
						<Popup>
							<b>{sumutListSensor[1]['locationName']}</b>
							<br/>
							Klik untuk menetapkan pilihan lokasi sensor menjadi {sumutListSensor[1]['locationName']}
						</Popup>
					</Marker>

				</MapContainer>

				<div>
					<div className={'centerTitle'}>
						Informasi Pilihan Lokasi
					</div>

					<div>
						<table className={'selectedInfo'}>
							<tbody>
								<tr>
									<td className={'cellTitle'}>
										Lokasi Sensor
									</td>
									<td className={'cellValue'}>
										{selectedLoc['locationName']}
									</td>
								</tr>
								<tr>
									<td className={'cellTitle'} rowSpan={2}>
										Koordinat Sensor
									</td>
									<td className={'cellValue'}>
										latitude
									</td>
									<td className={'cellValue'}>
										{selectedLoc['latitude']}
									</td>
								</tr>
								<tr>
									<td className={'cellValue'}>
										longitude
									</td>
									<td className={'cellValue'}>
										{selectedLoc['longitude']}
									</td>
								</tr>
							</tbody>
						</table>
						<Link
							to={'/overview/selected-sensor'}
							state={
								{
									locationName: selectedLoc['locationName'],
									latitude	: selectedLoc['latitude'],
									longitude	: selectedLoc['longitude'],
									nodeID		: selectedLoc['nodeID']
								}
							}
						>
							<Button className={'btn--primary--outline button-margin-1rem'}> Lanjutkan ke Dashboard</Button>
						</Link>

					</div>
				</div>
			</div>

		</div>

	);
}

export default Sumut;