import React, {useEffect, useRef} from 'react';
import './weatherStats.css';
import {dummyWStatsData} from './dummyData';
import {getInfoSVG, getInterpTitle, getInterpValue, getTrendStatus, interpHandler} from '../../../utils/generator';
import {formatUnits, getDateandMonth, getDateAndTime} from '../../../utils/textFormatter';
import {Line} from 'react-chartjs-2';

import {
	Chart as ChartJS,
	TimeScale, //Import timescale instead of category for X axis
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
} from 'chart.js';
ChartJS.register(
	TimeScale, //Register timescale instead of category for X axis
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);


export const WeatherStats = (props) => {
	const data = props.data;
	const tempSeries = data['temperature']['ts'];
	const rhSeries = data['relative_humidity']['ts'];

	const tempData = data['temperature'];
	const isTempHigh = interpHandler(tempData, 'temperature');
	const interpTempValue = getInterpValue(tempData, 'temperature', isTempHigh);
	const tempIcon = getInfoSVG('temperature', isTempHigh);
	const interpTempTitle = getInterpTitle('temperature', isTempHigh);

	const tempLastValue = formatUnits('temperature', tempData['last']);
	const lastTempRecorded = getDateAndTime(new Date(tempData['last_recorded']));
	const annualTemp = formatUnits('temperature', tempData['annual_mean']);

	const tempTrendTuple = getTrendStatus('temperature', tempData['status']);
	const tempTrend = tempTrendTuple[0];
	const tempTrendCaption = tempTrendTuple[1];
	const tempCritPoint =getDateandMonth(new Date(tempData['crit_point']));

	const tempFluctStats = {
		mean:tempData['mean'],
		stdev:tempData['stdev'],
		skew:tempData['skew'],
		kurt:tempData['kurt']
	};

	const tempTimeSeries = {
		labels: tempSeries.map(function (d) {
			return d.x;
		}),
		datasets: [
			{
				label: 'Suhu Udara',
				data: tempSeries.map(function (d) {
					return d.y;
				}),
				borderColor: 'rgba(129, 135, 220,0.9)',
				borderWidth: 2,
				tension: 0.4,
				elements: {
					point:{
						radius: 2
					}
				}
			},
		]
	};


	const rhData = data['relative_humidity'];
	const isRHHigh = interpHandler(rhData, 'relative_humidity');
	const rhIcon = getInfoSVG('relative_humidity', isRHHigh);
	const interpRHTitle = getInterpTitle('relative_humidity', isRHHigh);
	const interpRHValue = getInterpValue(rhData, 'relative_humidity', isRHHigh);

	const rhLastValue = formatUnits('relative_humidity', rhData['last']);
	const lastRHRecorded = getDateAndTime(new Date(rhData['last_recorded']));

	const annualRH = formatUnits('relative_humidity', rhData['annual_mean']);

	const rhTrendTuple = getTrendStatus('relative_humidity', rhData['status']);
	const rhTrend = rhTrendTuple[0];
	const rhTrendCaption = rhTrendTuple[1];
	const rhCritPoint =getDateandMonth(new Date(rhData['crit_point']));

	const rhFluctStats = {
		mean:rhData['mean'],
		stdev:rhData['stdev'],
		skew:rhData['skew'],
		kurt:rhData['kurt']
	};
	const rhTimeSeries = {
		labels: rhSeries.map(function (d) {
			return d.x;
		}),
		datasets: [
			{
				label: 'Kelembapan Udara',
				data: rhSeries.map(function (d) {
					return d.y;
				}),
				borderColor: 'rgba(180, 65, 142,0.9)',
				borderWidth: 2,
				tension: 0.4,
				elements: {
					point:{
						radius: 2
					}
				}
			},
		]
	};

	const bothTimeSeries = {
		labels: tempSeries.map(function (d) {
			return d.x;
		}),
		datasets: [

			{
				label: 'Suhu Udara',
				data: tempSeries.map(function (d) {
					return d.y;
				}),
				yAxisID: 'ytemp',
				fill: false,
				borderColor: 'rgba(129, 135, 220,0.9)',
				borderWidth: 2,
				tension: 0.4,
				showTooltips: false,
				elements: {
					point:{
						radius: 2
					}
				}
			},
			{
				label: 'Kelembapan Udara',
				data: rhSeries.map(function (d) {
					return d.y;
				}),
				yAxisID: 'ymoist',
				borderColor: 'rgba(180, 65, 142,0.9)',
				borderWidth: 2,
				tension: 0.4,
				showTooltips: false,
				elements: {
					point:{
						radius: 2
					}
				}
			},

		]
	};


	const statsPanelRef = useRef(null);

	function clickRotate() {
		statsPanelRef.current.classList.toggle('spin');
	}

	useEffect(()=>{
		statsPanelRef.current.addEventListener('click', clickRotate);

	}, [ statsPanelRef]);

	return(
		<div className={'WeatherStatsPanel'}>
			<div className={'StatsPanelContainer'}>
				<div className={'StatsPanel'} ref={statsPanelRef}>
					<div className={'StatsPanelContents PanelSuhu'}>
						<div className={'PanelTitle'}>
                            Gambaran Suhu Udara
						</div>
						<div className={'StatsPanelDepth'}>
							<div className={'WeatherStatsContainer'}>

								<div className={'WeatherStats'}>
									<div className={'InterpIconContainer'}>
										{tempIcon}
									</div>
									<div className={'WeatherStatsTable'}>
										<div className={'WeatherStatsInfo'}>
											<table>
												<tbody>
													<tr>
														<td className={'CellTitle'}>{interpTempTitle}</td>
														<td className={'CellTitle'}>Suhu Tahunan</td>
													</tr>
													<tr>
														<td className={`${(isTempHigh) ? 'TempHigh' : 'TempLow'}`}>{interpTempValue}</td>
														<td className={'CellValue'}>{annualTemp}</td>
													</tr>

												</tbody>
											</table>
										</div>
										<div className={'WeatherStatsInfo'}>
											<table>
												<tbody>
													<tr>
														<td className={'CellTitle'} colSpan={2}>Tren Suhu : <span className={`${(isTempHigh) ? 'TrendHigh' : 'TrendLow'}`}> {tempTrend} </span></td>
													</tr>
													<tr>
														<td className={'CellCaption'} colSpan={2}>{tempTrendCaption}</td>
													</tr>
													<tr>
														<td colSpan={2} className={`${(isTempHigh) ? 'DateLow' : 'DateHigh'}`}>{tempCritPoint}</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
								<div className={'DivLine'}></div>
								<div className={'DistStatstable'}>
									<div className={'DistCaption'}>
                                        Statistik Fluktuasi Suhu Udara
									</div>
									<div className={'DistTable'}>
										<table className={'DistTable'}>
											<tbody>
												<tr>
													<td className={'DistCellCaption'}>Standar Deviasi</td>
													<td className={'DistCellValue'}>{tempFluctStats.stdev}</td>
												</tr>
												<tr>
													<td className={'DistCellCaption'}>Skewness</td>
													<td className={'DistCellValue'}>{tempFluctStats.skew}</td>
												</tr>
												<tr>
													<td className={'DistCellCaption'}>Kurtosis</td>
													<td className={'DistCellValue'}>{tempFluctStats.kurt}</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
							<div className={'WeatherSeries'}>
								<div className={'WSChartTitle'}>
                                    Grafik Suhu dan Kelembapan Udara
								</div>
								<div className={'WSChartContainer'}>
									<Line
										className={'LineChart'}
										data={bothTimeSeries}
										options={{
											responsive:false,
											maintainAspectRatio: false,
											scales: {
												x: {

													display:true,
													type: 'time',
													ticks: {
														color:'#FFFFFF'
													}

												},
												ytemp: {
													display:true,
													ticks: {
														callback: function(value, index, values) {
															return value + '°';
														},
														color:'#ebebff'
													}

												},
												ymoist: {
													display:true,
													position:'right',
													ticks: {
														callback: function(value, index, values) {
															return value + '%';
														},
														color:'rgba(255, 112, 150,0.8)'
													}

												}
											},
											plugins: {
												title: {
													display: false,
													text: 'Suhu Udara',
													color:'#FFFFFF'
												},
												legend: {
													display:false,
													position: 'top',
													labels:{
														boxWidth:10,
														boxHeight:10,
													}
												},
											}
										}}
									/>
								</div>

							</div>
						</div>
					</div>
					<div className={'StatsPanelContents PanelKelembapan'}>
						<div className={'PanelTitle'}>
                            Gambaran Kelembapan Udara
						</div>
						<div className={'StatsPanelDepth'}>
							<div className={'WeatherStatsContainer'}>

								<div className={'WeatherStats'}>
									<div className={'InterpIconContainer'}>
										{rhIcon}
									</div>
									<div className={'WeatherStatsTable'}>
										<div className={'WeatherStatsInfo'}>
											<table>
												<tbody>
													<tr>
														<td className={'CellTitle'}>{interpRHTitle}</td>
														<td className={'CellTitle'}>Kelembapan Tahunan</td>
													</tr>
													<tr>
														<td className={`${(isRHHigh) ? 'TempLow' : 'TempHigh'}`}>{interpRHValue}</td>
														<td className={'CellValue'}>{annualRH}</td>
													</tr>

												</tbody>
											</table>
										</div>
										<div className={'WeatherStatsInfo'}>
											<table>
												<tbody>
													<tr>
														<td className={'CellTitle'} colSpan={2}>Tren Kelembapan : <span className={`${(!isRHHigh) ? 'TrendHigh' : 'TrendLow'}`}> {rhTrend} </span></td>
													</tr>
													<tr>
														<td className={'CellCaption'} colSpan={2}>{rhTrendCaption}</td>
													</tr>
													<tr>
														<td colSpan={2} className={`${(!isRHHigh) ? 'DateLow' : 'DateHigh'}`}>{rhCritPoint}</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
								<div className={'DivLine'}></div>
								<div className={'DistStatstable'}>
									<div className={'DistCaption'}>
                                        Statistik Fluktuasi Kelembapan Udara
									</div>
									<div className={'DistTable'}>
										<table className={'DistTable'}>
											<tbody>
												<tr>
													<td className={'DistCellCaption'}>Standar Deviasi</td>
													<td className={'DistCellValue'}>{rhFluctStats.stdev}</td>
												</tr>
												<tr>
													<td className={'DistCellCaption'}>Skewness</td>
													<td className={'DistCellValue'}>{rhFluctStats.skew}</td>
												</tr>
												<tr>
													<td className={'DistCellCaption'}>Kurtosis</td>
													<td className={'DistCellValue'}>{rhFluctStats.kurt}</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
							<div className={'WeatherSeries'}>
								<div className={'WSChartTitle'}>
                                    Grafik Suhu dan Kelembapan Udara
								</div>
								<div className={'WSChartContainer'}>
									<Line
										className={'LineChart'}
										data={bothTimeSeries}
										options={{
											responsive:false,
											maintainAspectRatio: false,
											scales: {
												x: {

													display:true,
													type: 'time',
													ticks: {
														color:'#FFFFFF'
													}

												},
												ytemp: {
													display:true,
													ticks: {
														callback: function(value, index, values) {
															return value + '°';
														},
														color:'#ebebff'
													}

												},
												ymoist: {
													display:true,
													position:'right',
													ticks: {
														callback: function(value, index, values) {
															return value + '%';
														},
														color:'rgba(255, 112, 150,0.8)'
													}

												}
											},
											plugins: {
												title: {
													display: false,
													text: 'Suhu Udara',
													color:'#FFFFFF'
												},
												legend: {
													display:false,
													position: 'top',
													labels:{
														boxWidth:10,
														boxHeight:10,
													}
												},
											}
										}}
									/>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);

};

export default WeatherStats;

// <table className={'WeatherStatsTable'}>
//     <tbody>
//     <tr>
//     <td className={'CellTitle'}>Suhu Rendah</td>
// <td className={'CellTitle'}>Suhu Tahunan</td>
// </tr>
// <tr>
//     <td className={`${(isTempHigh) ? 'TempHigh' : 'TempLow'}`}>{interpTempValue}</td>
//     <td className={'CellValue'}>{annualTemp}</td>
// </tr>
// <tr>
//     <td className={`CellTitle`}>Suhu Terakhir</td>
//     <td className={'CellTitle'}>Tren Suhu : <span className={`${(isTempHigh) ? 'TrendHigh' : 'TrendLow'}`}> {tempTrend} </span></td>
// </tr>
// <tr>
//     <td className={'CellCaption'}>{lastTempRecorded}</td>
//     <td className={'CellCaption'}>{tempTrendCaption}</td>
// </tr>
// <tr>
//     <td className={'CellValue'}>{tempLastValue}</td>
//     <td className={`${(isTempHigh) ? 'TempLow' : 'TempHigh'}`}>{tempCritPoint}</td>
// </tr>
// </tbody>
// </table>