

import React, { useEffect, useState } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

import'./maps.css';


let DefaultIcon = L.icon({
	iconUrl: icon,
	shadowUrl: iconShadow,
});

export default function MapView(props) {

	const [isStatusDisplayed, setIsStatusDisplayed] = useState(false);

	const showStation = (n)=>{
		L.Marker.prototype.options.icon = DefaultIcon;
		const stationMarker = L.marker([2.50894, 98.62638]).addTo(n);
		stationMarker.bindPopup('<div style={\'text-align: center\'}> <b>NearestStation</b></div>').openPopup();

		L.Marker.prototype.options.icon = DefaultIcon;
		const station2Options = {
			title: 'Dolok Sanggul',
			clickable: true,
		};
		const station2 = L.marker([2.23062, 98.57336], station2Options).addTo(n);
		station2.bindPopup('<div style={\'text-align: center\'}> <b>Dolok Sanggul</b></div>').openPopup();
	};

	useEffect(() => {
		const container = L.DomUtil.get('map');

		if (container != null) {
			container._leaflet_id = null;
		}
		const map = L.map('map').setView([props.lat, props.long], 13);


		const ltile = new L.TileLayer('https://viz.undo.it/map/{z}/{x}/{y}.png', {
			maxZoom: 50,
			attribution: '&copy; <a href="http://bandungfe.net"> Bandung Fe Institute</a>'
		});

		ltile.addTo(map);


		L.Marker.prototype.options.icon = DefaultIcon;
		const marker = L.marker([props.lat, props.long]).addTo(map);
		marker.bindPopup('<div style={\'text-align: center\'}> <b>Sumbul, Dairi</b></div>').openPopup();


	});



	return (
		<div className={'MapContent'} id="map"></div>
	);

}