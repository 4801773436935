
import {useEffect, useState} from 'react';
import { Bar } from 'react-chartjs-2';
import './Histogram.css';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';

  


ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);
var measurementsDict = {
	temperature :'Suhu Udara',
	relative_humidity :'Kelembapan Udara',
	soil_temperature :'Suhu Tanah',
	soil_moisture :'Kelembapan Tanah',
	ambient_light :'Intensitas Cahaya',
	daging_ayam : 'Daging Ayam',
	daging_sapi : 'Daging Sapi',
	gula_pasir : 'Gula Pasir',
	minyak_goreng : 'Minyak Goreng',
	telur_ayam : 'Telur Ayam'
};


function Histogram(props){
	const{measurement} = props;

	const [data, setData] = useState([]);


	useEffect(()=>{
		if(data.length > 0){
			console.log();
		} else {
			retrieveBarData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[data]);

    
	const formatDateToString = (n)=>{

		const date = new Date(n);
		// 01, 02, 03, ... 29, 30, 31
		const dd = (date.getDate() < 10 ? '0' : '') + date.getDate();
		// 01, 02, 03, ... 10, 11, 12
		const mm = ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1);
		// 1970, 1971, ... 2015, 2016, ...
		const yyyy = date.getFullYear();
		return (yyyy + '-' + mm + '-' + dd);
	};

	const retrieveBarData = async ()=>{
		let current = new Date();
		current.setMonth(current.getMonth() - 1);
		const apiDate = formatDateToString(current);
		const url = `https://iotsf.bfitech.co.id:6717/api/${props.sensor_id}/${measurement}/hist/from=${apiDate}`;

		try{
			const response = await fetch(url);
			const d = await response.json();
			let barData;
			if(props.measurement ==='temperature'){
				barData = d.map((d) => ({ state:`${d.x.toFixed(1)}°C` , frequency: d.y }));
			} else if(props.measurement ==='relative_humidity'){
				barData = d.map((d) => ({ state:`${d.x.toFixed(1)}%` , frequency: d.y }));
			} else{
				barData = d.map((d) => ({ state:`${d.x.toFixed(1)}` , frequency: d.y }));
			}

			setData(barData);

             

		}catch (e) {
			console.log(e);
		}
	};
    
    
	const dc = {
		labels: data.map(function (d) {
			return d.state;
		}),
		datasets: [
			{
				label: `Frekuensi ${measurementsDict[props.measurement]}`,
				data: data.map(function (d) {
					return d.frequency;
				}),
				backgroundColor:['rgba(8, 154, 243, 1)']
			},
		]
	};

	return (

		<Bar className={'BarChart'}
			height="100%"
			width="100%"
			data={dc}
			options={{
				responsive: true,
				maintainAspectRatio: false,
				scales: {
					x: {
						display:true,
						ticks: {
							color:'#FFFFFF'
						}

					},
					y: {
						display:true,
						ticks: {
							color:'#FFFFFF'
						}

					}
				},
				plugins: {

					title: {
						display: false,
						text: `${measurementsDict[props.measurement]}`,
						color:'#FFFFFF'

					},
					legend: {
						display: false,
						position: 'bottom'
					}
				}
			}}
		/>


	);
}
export default Histogram;

