import {useEffect, useState} from 'react';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-moment';
import './multiline.css';
import {
	Chart as ChartJS,
	TimeScale, //Import timescale instead of category for X axis
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
} from 'chart.js';

import {comColor, comColorAlpha} from '../../../utils/dictionary';
import dummyDataset from './dummyData';

ChartJS.register(
	TimeScale, //Register timescale instead of category for X axis
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);



export const MultiLineChart = (props)=>{
	const data = props.data;
	const hargaBawangMerah = data['bawang_merah'];
	const hargaBawangPutih = data['bawang_putih'];
	const hargaBeras = data['beras'];
	const hargaDagingAyam = data['daging_ayam'];
	const hargaDagingSapi = data['daging_sapi'];
	const hargaCabaiRawit = data['cabai_rawit'];
	const hargaCabaiMerah = data['cabai_merah'];
	const hargaTelurAyam = data['telur_ayam'];
	const hargaGulaPasir = data['gula_pasir'];
	const hargaMinyakGoreng = data['minyak_goreng'];


	const chartData = {
		labels: hargaBawangMerah.map(function (d) {
			return d.x;
		}),
		datasets:[
			{
				label: 'Bawang Merah',
				data: hargaBawangMerah.map(function (d) {
					return d.y;
				}),
				backgroundColor:comColor['bawang_merah'],
				borderColor:comColorAlpha['bawang_merah'],
				borderWidth:1,
				tension: 0.4
			},
			{
				label: 'Bawang Putih',
				data: hargaBawangPutih.map(function (d) {
					return d.y;
				}),
				backgroundColor:comColor['bawang_putih'],
				borderColor:comColorAlpha['bawang_putih'],
				borderWidth:1,
				tension: 0.4
			},
			{
				label: 'Daging Ayam',
				data: hargaDagingAyam.map(function (d) {
					return d.y;
				}),
				backgroundColor:comColor['daging_ayam'],
				borderColor:comColorAlpha['daging_ayam'],
				borderWidth:1,
				tension: 0.4
			},
			{
				label: 'Daging Sapi',
				data: hargaDagingSapi.map(function (d) {
					return d.y;
				}),
				backgroundColor:comColor['daging_sapi'],
				borderColor:comColorAlpha['daging_sapi'],
				borderWidth:1,
				tension: 0.4
			},
			{
				label: 'Telur Ayam',
				data: hargaTelurAyam.map(function (d) {
					return d.y;
				}),
				backgroundColor:comColor['telur_ayam'],
				borderColor:comColorAlpha['telur_ayam'],
				borderWidth:1,
				tension: 0.4
			},
			{
				label: 'Cabai Merah',
				data: hargaCabaiMerah.map(function (d) {
					return d.y;
				}),
				backgroundColor:comColor['cabai_merah'],
				borderColor:comColorAlpha['cabai_merah'],
				borderWidth:1,
				tension: 0.4
			},
			{
				label: 'Cabai Rawit',
				data: hargaCabaiRawit.map(function (d) {
					return d.y;
				}),
				backgroundColor:comColor['cabai_rawit'],
				borderColor:comColorAlpha['cabai_rawit'],
				borderWidth:1,
				tension: 0.4
			},
			{
				label: 'Gula Pasir',
				data: hargaGulaPasir.map(function (d) {
					return d.y;
				}),
				backgroundColor:comColor['gula_pasir'],
				borderColor:comColorAlpha['gula_pasir'],
				borderWidth:1,
				tension: 0.4
			},
			{
				label: 'Minyak Goreng',
				data: hargaMinyakGoreng.map(function (d) {
					return d.y;
				}),
				backgroundColor:comColor['minyak_goreng'],
				borderColor:comColorAlpha['minyak_goreng'],
				borderWidth:1,
				tension: 0.4
			},
			{
				label: 'Beras',
				data: hargaBeras.map(function (d) {
					return d.y;
				}),
				backgroundColor:comColor['beras'],
				borderColor:comColorAlpha['beras'],
				borderWidth:1,
				tension: 0.4
			},


		]
	};

	return(
		<div className={'MultiLineChart'}>

			<div className={'GraphTitle'}> Harga Bahan Pokok </div>
			<div>
				<Line
					className={'MultiLine'}
					data={chartData}
					options={{
						responsive:true,
						maintainAspectRatio: false,
						scales: {
							x: {

								display:true,
								type: 'time',
								ticks: {
									color:'#FFFFFF'
								},
								grid: {
									display: false,
									drawBorder: false,
								}

							},
							y: {
								display:true,
								ticks: {
									color:'#FFFFFF'
								},
								grid: {
									display: true,
									drawBorder: true,
									color:'rgba(255, 255, 255,0.8)'
								}


							}
						},
						plugins: {
							legend: {
								position: 'right',
								labels:{
									boxWidth:10,
									boxHeight:10,
									color:'#FFFFFF',
									padding:10
								},

							},
						}
					}}
				/>
			</div>
		</div>
	);
};