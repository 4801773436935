/* eslint-disable no-unused-vars */

import React, {useEffect, useState} from 'react';
import './SoilWidget.css';
import SvgRichNutrition from './icons/RichNutrition';
const SoilWidget = (props) => {

	const data = props.data;
	const locName = props.locName;
	const nitrogenLv = data['nitrogen'];
	const phosphorousLv = data['phosphorous'];
	const potassiumLv = data['potassium'];
	const conductivityLv = data['soil_conductivity'];
	const phLevel = data['soil_ph'];


	return(
		<div className={'SoilWidgetContainer'}>

			<div className="SWIcon">
				<SvgRichNutrition height={'180px'} />
			</div> 

			<div className="SWDetails">
				< div className={'SoilWidgetTitle'}>
					<div className={'SoilWidgetTitle'} >Gambaran Umum Tanah</div>
					<span className={'SoilWidgetCaption'}>{locName}</span>
				</div>
				<div className={'MineralWrapper'}>
					<table className="MineralTable">
						<tbody>
							<tr>
								<td className={'MTableCell NDetails TextInfo'}>Nitrogen</td>
							</tr>
							<tr>
								<td className={'MTableCell NDetails MineralValue'}>{nitrogenLv}</td>
							</tr>
							<tr>
								<td className={'MTableCell NDetails TextInfo'}>ppm</td>
							</tr>
						</tbody>
					</table>
					<table className="MineralTable">
						<tbody>
							<tr>
								<td className={'MTableCell PDetails TextInfo'}>Fosfat</td>
							</tr>
							<tr>
								<td className={'MTableCell PDetails MineralValue'}>{phosphorousLv}</td>
							</tr>
							<tr>
								<td className={'MTableCell PDetails TextInfo'}>ppm</td>
							</tr>
						</tbody>
					</table>
					<table className="MineralTable">
						<tbody>
							<tr>
								<td className={'MTableCell KDetails TextInfo'}>Kalium</td>
							</tr>
							<tr>
								<td className={'MTableCell KDetails MineralValue'}>{potassiumLv}</td>
							</tr>
							<tr>
								<td className={'MTableCell KDetails TextInfo'}>ppm</td>
							</tr>
						</tbody>
					</table>             
				</div>
				<div className={'ExtraInfo'}>
					<table className="EITable">
						<tbody>
							<tr>
								<td colSpan="2" className={'EIFirstCol'}>Konduktivitas Tanah</td>
								<td className={'EIValue'}>{conductivityLv} μS/cm</td>
							</tr>
							<tr>
								<td colSpan="2" className={'EIFirstCol'}>pH Tanah</td>
								<td className={'EIValue'}>{phLevel.toFixed(3)}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);

};

export default SoilWidget;

