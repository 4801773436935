import React, {useState, useEffect, useRef }from 'react';
import './overview.css';
import MapView from '../../views/maps/maps';
import MSTView from '../../views/mst/mst';
import {PriceWidgets} from '../../views/widgets/price/PriceWidgets';
import {MultiLineChart} from '../../views/chart/multiline/multilineComPrices';
import {formatDateToString} from '../../utils/textFormatter';
import {generateAPIURL} from '../../utils/generator';

import {cyclePredKeyGen} from '../../utils/generator';
import {SensorMap} from '../../views/sensorMap/sensorMap';
import WeatherStats from '../../views/widgets/WeatherStatsPanel/WeatherStats';
import SoilWidget from '../../views/widgets/soilPanel/SoilWidget';
import {SthGraph} from '../../views/widgets/soilTempHumGraph/sthGraph';

import axios from 'axios';
import {AmbGraph} from '../../views/widgets/ambiencePanel/ambientPanel';

import SvgLogo from '../../static/Logo';
import LoadingBar from '../../components/LoadingBar/LoadingBar';
import {useParams, useLocation, Link} from "react-router-dom";
import {Button} from "../../components/Button/Button";

export const Overview = () => {

	const { type } = useParams();

	const locationName = useLocation().state.locationName;;
	const latitude = useLocation().state.latitude;
	const longitude = useLocation().state.longitude;
	const nodeID = useLocation().state.nodeID;

	//console.log(locationName, latitude, longitude, nodeID);
	// const locationName = 'Sumbul, Dairi'
	// const latitude = '2.7605';
	// const longitude = '98.45527';
	// const nodeID = 'node_2';
	// const locationName = 'Food Estate, Humbahas'
	// const latitude = '2.38142896';
	// const longitude = '98.704115';
	// const nodeID = 'node_9';
	//Sumbul 2.7605, 98.45527

	const [isRetrieved, setIsRetrieved] = useState(false);
	const [isLoading, setIsLoading] = useState();
	const [isAgroClimate, setIsAgroClimate] = useState(true);

	//retrievePrice -To Be Used in Price Widget and MST
	const [graphData, setGraphData] = useState(); // -- mstURL
	const [nPrice, setNPrice] = useState(); // nPriceURL
	const [dPrice, setDPrice] = useState(); // dPriceURL
	const [comPrices, setComPrices] = useState(); // allPriceURL

	//retrieveSoilData parameter-sensorID
	const [airStats, setAirStats] = useState(); // airStatsURL
	const [tempSeries, setTempSeries] = useState();
	const [rhSeries, setRhSeries] = useState();
	const [lastTemp, setLastTemp] = useState(); // tempSeriesURL
	const [lastHum, setLastHum] = useState(); // humidSeriesURL
	const [lastPointRecorded, setLastPointRecorded] = useState('');

	const [soilData, setSoilData] = useState(); //soilInfoURL
	const [soilTempSeries, setSoilTempSeries] = useState(); //soilTempSeriesURL
	const [moistureSeries, setMoistureSeries] = useState(); //soilMoistSeriesURL
	const [ambLightSeries, setAmbLightSeries] = useState(); //soilMoistSeriesURL
	const [conSeries, setConSeries] = useState(); //soilMoistSeriesURL
	const [solarVoltSeries, setSolarVoltSeries] = useState(); //soilMoistSeriesURL

	const [soilTempHist, setSoilTempHist] = useState(); //soilTempHistURL
	const [moistureHist, setMoistureHist] = useState(); //soilMoistHistURL
	const [ambLightHist, setAmbLightHist] = useState(); //soilMoistHistURL
	const [conHist, setConHist] = useState(); //soilMoistHistURL
	const [solarVoltHist, setsolarVoltHist] = useState(); //soilMoistHistURL

	//retrieveStats --parameter: SensorID, measurement
	const [stationData, setStationData] = useState(); // wStationURL
	const [nearestStationData, setNearestStationData] = useState();
	const [powerAll, setPowerAll] = useState(); //powerTempURL
	const [weatherObject, setWeatherObject] = useState({});
	const [sthGraphObject, setSthGraphObject] = useState({});

	const [isFirstRowRetrieved, setIsFirstRowRetrieved] = useState(false);
	const [isSecondRowRetrieved, setIsSecondRowRetrieved] = useState(false);
	const [isThirdRowRetrieved, setIsThirdRowRetrieved] = useState(false);
	const [isObjectAssigned, setIsObjectAssigned] = useState(false);

	const level = 'province';
	const region = 'sumatera_utara';
	const measurementTemp = 'temperature';
	const measurementHumid = 'relative_humidity';
	const measurementSoilTemp = 'soil_temperature';
	const measurementSoilMoisture = 'soil_moisture';
	const ambLight = 'ambient_light';
	const conMeasurement= 'soil_conductivity';

	const retrieveFirstRow = async ()=>{

		const d3 = new Date();
		d3.setDate(d3.getDate() - 7);
		const lastTwoWeek = formatDateToString(d3);

		const d4 = new Date();
		d4.setMonth(d4.getMonth() - 1);
		const lastMonth = formatDateToString(d4);

		const apiURL = process.env.REACT_APP_API_URL;

		const airStatsURL = `${apiURL}/${nodeID}/airstats`;
		const tempSeriesURL = `${apiURL}/${nodeID}/${measurementTemp}/from=${lastMonth}`;
		const humidSeriesURL = `${apiURL}/${nodeID}/${measurementHumid}/from=${lastMonth}`;
		const wStationURL =`${apiURL}/station/${region}?lat=${latitude}&long=${longitude}`;
		const powerAllURL = `${apiURL}/power/all?lat=${latitude}&long=${longitude}`;

		const getAirStats = axios.get(airStatsURL);
		const getTempSeries = axios.get(tempSeriesURL);
		const getHumidSeries = axios.get(humidSeriesURL );
		const getStationData = axios.get(wStationURL);
		const getPowerAll = axios.get(powerAllURL);

		axios.all([
			getAirStats,
			getTempSeries, //5
			getHumidSeries, //6
			getStationData,//12
			getPowerAll,//13
		]).then(
			axios.spread((...allData)=>{
				setAirStats(allData[0].data);
				setTempSeries(allData[1].data);
				setLastTemp(allData[1].data[allData[1].data.length - 1].y.toFixed(1));
				setLastPointRecorded(allData[1].data[allData[1].data.length - 1].x);
				setRhSeries(allData[2].data);
				setLastHum(allData[2].data[allData[2].data.length - 1].y.toFixed(1));

				setStationData(allData[3].data);
				setPowerAll(allData[4].data);

				const wobj = {
					'relative_humidity' : {
						'interp_relative_humidity':allData[0].data['interp_relative_humidity'],
						'low_relative_humidity':allData[0].data['low_relative_humidity'],
						'high_relative_humidity':allData[0].data['high_relative_humidity'],
						'last':allData[2].data[allData[2].data.length - 1].y.toFixed(1),
						'last_recorded' : allData[1].data[allData[1].data.length - 1].x,
						'ts' : allData[2].data,
						'status': allData[4].data['humidity']['cycle']['status'],
						'crit_point': allData[4].data['humidity']['cycle'][cyclePredKeyGen(allData[4].data['humidity']['cycle']['status'])],
						'annual_mean': allData[4].data['humidity']['annual_mean']['value'],
						'mean': allData[4].data['humidity']['annual_mean']['fluctuation']['mean'],
						'stdev': allData[4].data['humidity']['annual_mean']['fluctuation']['stdev'],
						'skew': allData[4].data['humidity']['annual_mean']['fluctuation']['skew'],
						'kurt': allData[4].data['humidity']['annual_mean']['fluctuation']['kurt'],
					},
					'temperature' : {
						'interp_temperature':allData[0].data['interp_temperature'],
						'low_temperature':allData[0].data['low_temperature'],
						'high_temperature':allData[0].data['high_temperature'],
						'last':allData[1].data[allData[1].data.length - 1].y.toFixed(1),
						'last_recorded' :  allData[1].data[allData[1].data.length - 1].x,
						'ts' : allData[1].data,
						'status': allData[4].data['temperature']['cycle']['status'],
						'crit_point': allData[4].data['temperature']['cycle'][cyclePredKeyGen(allData[4].data['temperature']['cycle']['status'])],
						'annual_mean': allData[4].data['temperature']['annual_mean']['value'],
						'mean': allData[4].data['temperature']['annual_mean']['fluctuation']['mean'],
						'stdev': allData[4].data['temperature']['annual_mean']['fluctuation']['stdev'],
						'skew': allData[4].data['temperature']['annual_mean']['fluctuation']['skew'],
						'kurt': allData[4].data['temperature']['annual_mean']['fluctuation']['kurt'],
					},
				};
				setWeatherObject(wobj);

				setIsFirstRowRetrieved(true);
			})
		);

	};

	const retrieveSecondRow = async ()=>{

		const d3 = new Date();
		d3.setDate(d3.getDate() - 7);
		const lastTwoWeek = formatDateToString(d3);

		const d4 = new Date();
		d4.setMonth(d4.getMonth() - 1);
		const lastMonth = formatDateToString(d3);

		const apiURL = process.env.REACT_APP_API_URL;

		const tempSeriesURL = `${apiURL}/${nodeID}/${measurementTemp}/from=${lastTwoWeek}`;
		const humidSeriesURL = `${apiURL}/${nodeID}/${measurementHumid}/from=${lastTwoWeek}`;
		const ambSeriesURL = `${apiURL}/${nodeID}/${ambLight}/from=${lastTwoWeek}`;
		const conSeriesURL = `${apiURL}/${nodeID}/${conMeasurement}/from=${lastTwoWeek}`;
		const soilTempSeriesURL = `${apiURL}/${nodeID}/${measurementSoilTemp}/from=${lastTwoWeek}`;
		const soilMoistSeriesURL = `${apiURL}/${nodeID}/${measurementSoilMoisture}/from=${lastTwoWeek}`;

		const soilInfoURL = `${apiURL}/${nodeID}/soil_info`;

		const ambHistURL = `${apiURL}/${nodeID}/${ambLight}/hist/from=${lastMonth}`;
		const conHistURL = `${apiURL}/${nodeID}/${conMeasurement}/hist/from=${lastMonth}`;
		const soilTempHistURL = `${apiURL}/${nodeID}/${measurementSoilTemp}/hist/from=${lastMonth}`;
		const soilMoistHistURL = `${apiURL}/${nodeID}/${measurementSoilMoisture}/hist/from=${lastMonth}`;

		const solarVoltURL = `${apiURL}/${nodeID}/solar_voltage/from=${lastTwoWeek}`;
		const solarVoltHistURL = `${apiURL}/${nodeID}/solar_voltage/hist/from=${lastMonth}`;

		axios.all([
			axios.get(ambSeriesURL),
			axios.get(conSeriesURL ),
			axios.get(soilTempSeriesURL ),
			axios.get(soilMoistSeriesURL),
			axios.get(soilInfoURL),

			axios.get(ambHistURL),
			axios.get(conHistURL),
			axios.get(soilTempHistURL),
			axios.get(soilMoistHistURL),

			axios.get(tempSeriesURL),
			axios.get(humidSeriesURL),

			axios.get(solarVoltURL),
			axios.get(solarVoltHistURL)

		]).then(
			axios.spread((...allData)=>{

				setAmbLightSeries(allData[0].data);
				setConSeries(allData[1].data);
				setSoilTempSeries(allData[2].data);
				setMoistureSeries(allData[3].data);

				setSoilData(allData[4].data);

				setAmbLightHist(allData[5].data);
				setConHist(allData[6].data);
				setSoilTempHist(allData[7].data);
				setMoistureHist(allData[8].data);
				setSolarVoltSeries(allData[11].data);
				setsolarVoltHist(allData[12].data);

				const sthObject ={
					'ambient_light':allData[0].data,
					'soil_conductivity':allData[1].data,
					'soil_temperature':allData[2].data,
					'soil_moisture':allData[3].data,


					'hist_ambient_light':allData[5].data,
					'hist_soil_conductivity':allData[6].data,
					'hist_soil_temperature':allData[7].data,
					'hist_soil_moisture':allData[8].data,

					'temperature':allData[9].data,
					'relative_humidity': allData[10].data,

					'solar_voltage':allData[11].data,
					'hist_solar_voltage':allData[12].data

				};
				setSthGraphObject(sthObject);

				setIsSecondRowRetrieved(true);
			})
		);

	};

	const retrieveEstateData2 = async ()=>{
		const d = new Date();
		d.setDate(d.getDate() - 1);
		const current = formatDateToString(d);

		const d2 = new Date();
		d2.setDate(d2.getDate() - 7);
		const lastWeek = formatDateToString(d2);

		const d3 = new Date();
		d3.setDate(d3.getDate() - 7);
		const lastTwoWeek = formatDateToString(d3);

		const d4 = new Date();
		d4.setMonth(d4.getMonth() - 1);
		const lastMonth = formatDateToString(d4);

		const apiURL = process.env.REACT_APP_API_URL;

		const mstURL = generateAPIURL('mst', level, region, current);
		const nPriceURL = generateAPIURL('price', level, region, current);
		const dPriceURL = generateAPIURL('price', level, region, lastWeek);
		const allPriceURL = `${apiURL}/price/all/${region}/from=${lastMonth}`;

		const getMST = axios.get(mstURL);
		const getNPrice = axios.get(nPriceURL);
		const getDPrice = axios.get(dPriceURL);
		const getAllPrice = axios.get(allPriceURL );


		axios.all([
			getMST,
			getNPrice,
			getDPrice,
			getAllPrice,
		],{
			withCredentials:true
		}).then(
			axios.spread((...allData)=>{
				setGraphData(allData[0].data);
				setNPrice(allData[1].data);
				setDPrice(allData[2].data);
				setComPrices(allData[3].data);

				setIsThirdRowRetrieved(true);

			})
		);

	};


	useEffect(()=>{
		if (isRetrieved){
			setIsObjectAssigned(true);
		} else if(!isRetrieved){
			retrieveFirstRow();
			retrieveSecondRow();
			retrieveEstateData2();
			setIsRetrieved(true);
		}
	},[isObjectAssigned, isRetrieved, isObjectAssigned]);

	return(
		<div className={'Overview'}>
			<div className={'TopBar'}>
				<div className={'TopBarLogo'}>
					<SvgLogo width={'2.5rem'} height={'2.5rem'}></SvgLogo>
				</div>
				<div className={'TopBarMenu'}>
					<div className={'TBContent Agroklimatologi'} onClick={()=>{
						setIsAgroClimate(true);}
					}>
						<h5>Agroklimatologi</h5>
					</div>
					<div className={'TBContent HargaBahanPokok'} onClick={()=>{
						setIsAgroClimate(false);}
					}>
						<h5>Harga Bahan Pokok</h5>
					</div>
				</div>
			</div>
			<div className={'TitleContainer'}>
				<h3> Tinjauan {(isAgroClimate) ? 'Agroklimatologi' : 'Harga Bahan Pokok'} di {locationName}</h3>
			</div>

			{isAgroClimate && (
				<div className={'ShowAgro'}>

					{isFirstRowRetrieved && (
						<div className={'FirstRow'}>

							<div className={'MapContainer'} >
								<SensorMap stationData={stationData} powerData={powerAll} wobj={weatherObject} location={[latitude, longitude]}/>
							</div>
							{/*<div className={'WSPanelContainer'}>*/}
							{/*	<WeatherStats data={weatherObject}/>*/}
							{/*</div>*/}
						</div>
					)}

					{!isFirstRowRetrieved && (
						<div className={'LoadingContainer'}>
							<LoadingBar />
						</div>
					)}

					{isSecondRowRetrieved && (
						<div className={'SecondRow'}>
							<div className={'SWidgetCont'}>
								<SoilWidget data={soilData} locName={locationName} />
							</div>

							<div className={'SthContainer'}>
								<SthGraph data={sthGraphObject} locName={locationName}/>
							</div>
							<div className={'AmbienceContainer'}>
								<AmbGraph data={sthGraphObject} locName={locationName}/>
							</div>
						</div>
					)}

					{ !isSecondRowRetrieved && (
						<div className={'LoadingContainer'}>
							<LoadingBar />
						</div>
					)}

				</div>
			)}

			<Link
				to={'/'}
			>
				<Button className={'btn--primary--outline button-margin-1rem'}>Pilih Lokasi Lain</Button>
			</Link>

			{!isAgroClimate && (
				<div className={'ShowPrice'}>
					{isThirdRowRetrieved && (
						<div className={'PriceAnalyticContainer'}>
							<div className={'PriceRow'}>
								<div className={'PriceGraphContainer'}>
									<MultiLineChart data={comPrices}/>
								</div>
								<div className={'PriceWidgetContainer'}>
									<div className={'FlexRowContainer'}>
										<PriceWidgets nPrice={nPrice} dPrice={dPrice} com={'bawang_merah'} level={level} region={region}/>
										<PriceWidgets nPrice={nPrice} dPrice={dPrice} com={'bawang_putih'} level={level} region={region}/>
									</div>
									<div className={'FlexRowContainer'}>
										<PriceWidgets nPrice={nPrice} dPrice={dPrice} com={'cabai_merah'} level={level} region={region}/>
										<PriceWidgets nPrice={nPrice} dPrice={dPrice} com={'cabai_rawit'} level={level} region={region}/>
									</div>
									<div className={'FlexRowContainer'}>
										<PriceWidgets nPrice={nPrice} dPrice={dPrice} com={'daging_sapi'} level={level} region={region}/>
										<PriceWidgets nPrice={nPrice} dPrice={dPrice} com={'daging_ayam'} level={level} region={region}/>
									</div>
									<div className={'FlexRowContainer'}>
										<PriceWidgets nPrice={nPrice} dPrice={dPrice} com={'beras'} level={level} region={region}/>
										<PriceWidgets nPrice={nPrice} dPrice={dPrice} com={'telur_ayam'} level={level} region={region}/>
									</div>
									<div className={'FlexRowContainer'}>
										<PriceWidgets nPrice={nPrice} dPrice={dPrice} com={'minyak_goreng'} level={level} region={region}/>
										<PriceWidgets nPrice={nPrice} dPrice={dPrice} com={'gula_pasir'} level={level} region={region}/>
									</div>
								</div>
							</div>
							<div className={'MSTRow'}>
								<div className={'MSTInfoContainer'}>
									<div className={'dummyCont'}>
										<div className={'MSTInfoTitle'}>
											Jaring Interkorelasi Harga
										</div>
										<div className={'MSTInfoDescription'}>
											<div className={'MSTInfoDescTitle'}>
												Deskripsi
											</div>
											<div className={'MSTInfoDesc'}> Jika titik melambangkan satu harga produk barang, maka garis yang menghubungkannya adalah kait-mengkait antara perubahan harga keduanya.
												Semakin tebal garisnya, makin kuat kait-mengkaitnya.</div>
										</div>
										<div className={'MSTNodeDescription'}>
											<div className={'NodeRow'}>
												<div className={'NodeElement'}>
													<div className={'CircleNode GreenNode'}></div>
												</div>
												<div className={'NodeInformation MSTInfoDesc'}>
													Harga komoditas turun dibandingkan harga minggu sebelumnya
												</div>
											</div>
											<div className={'NodeRow'}>
												<div className={'NodeElement'}>
													<div className={'CircleNode RedNode'}></div>
												</div>
												<div className={'NodeInformation MSTInfoDesc'}>
													Harga komoditas naik dibandingkan harga minggu sebelumnya
												</div>
											</div>
											<div className={'NodeRow'}>
												<div className={'NodeElement'}>
													<div className={'CircleNode WhiteNode'}></div>
												</div>
												<div className={'NodeInformation MSTInfoDesc'}>
													Harga komoditas tidak berubah dibandingkan harga minggu sebelumnya
												</div>
											</div>
											<div className={'NodeRow'}>
												<div className={'NodeElement'}>
													<div className={'EdgesNode'}></div>
												</div>
												<div className={'NodeInformation MSTInfoDesc'}>
													Tingkat keterkaitan antara harga komoditas
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className={'MSTContainer'}>
									<MSTView graphData={graphData.edges} nPrice={nPrice} dPrice={dPrice} level={'province'}/>
								</div>
							</div>

						</div>
					)}
					{!isThirdRowRetrieved && (
						<div className={'LoadingContainer'}>
							<LoadingBar />
						</div>
					)}
				</div>
			)}
		</div>
	);

};

export default Overview;
